.event-list-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
    background-color: #f9f9f9;
}

.event-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 834px;
}

.error-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
}

.error-message {
    background-color: #f8d7da;
    color: #721c24;
    padding: 20px;
    border-radius: 5px;
    margin: 20px 0;
    max-width: 834px;
  }
  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
  }

  .loading-message {
    background-color: bisque;
    color: black;
    padding: 20px;
    border-radius: 5px;
    margin: 20px 0;
    max-width: 834px;
  }

  .no-events {
    text-align: center;
    font-size: 18px;
    color: #555;
  }