/* EventCard.css */

.event-card {
    display: flex;
    flex-direction: row;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 16px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* max-width: 600px; */
    margin: 16px;
    transition: box-shadow 0.3s ease;
}

.event-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.event-date {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    /* background-color: #f0f0f0; */
    border-radius: 8px;
    margin-right: 16px;
    flex-shrink: 0;
}

.event-date-number {
    font-size: 24px;
    font-weight: bold;
    color: #4CAF50;
}

.event-date-month {
    font-size: 24px;
    font-weight: bold;
    color: #4CAF50;
}

.event-details {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
}

.event-details a {
    color: black;
    text-decoration: none;
}

.event-details a:hover {
    color: black;
    text-decoration: underline;
}

.event-title {
    font-size: 20px;
    font-weight: bold;
    margin: 0 0 8px;
    color: black;
}

.event-description {
    font-size: 16px;
    margin: 0 0 8px;
    color: #666;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* Show more text up to 2 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}


.event-time {
    font-size: 14px;
    color: #999;
    margin-top: 2px;
}

@media (max-width: 600px) {
    .event-card {
        flex-direction: row;
        max-width: 600px;
    }

    .event-date {
        margin-right: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 8px 16px;
        margin-right: 16px;
    }

    .event-card:hover {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }

    .event-time {
        margin-top: 2px;
        font-size: 14px;
        color: #999;
    }

    .event-description {
        -webkit-line-clamp: 3;
        /* Adjust for smaller screens to show more text */
    }

    .event-details {
        width: 100%;
    }
}